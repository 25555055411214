/* The loader container */
.loader_container {
  width: 100%;
  height: 100vh;
  background-color: #fff;
  z-index: 999999999999999;
}

.loader {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 200px;
  height: 200px;
  margin-top: -100px;
  margin-left: -100px;
  perspective: 1000px;
  transform-style: preserve-3d;
}

/* The cubes */
.cubes {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transform-style: preserve-3d;
  transform: rotateY(45deg) rotateZ(45deg);
}

/* The cube */
.cube {
  position: absolute;
  top: 0;
  left: 0;
  width: 50%;
  height: 50%;
  transform-origin: 50% 50%;
  transform-style: preserve-3d;
  transform: translateZ(-50px);
  animation: cube 2500ms cubic-bezier(0.66, 0.14, 0.56, 0.75) infinite;
}

.cube:nth-child(2) {
  top: 0;
  left: 50%;
  animation-delay: 50ms;
}

.cube:nth-child(3) {
  top: 50%;
  left: 0;
  animation-delay: 100ms;
}

.cube:nth-child(4) {
  top: 50%;
  left: 50%;
  animation-delay: 150ms;
}

@keyframes cube {
  0%,
  75%,
  100% {
    transform: translate3d(0px, 0px, 50px) rotateY(0deg) scale3d(0.9, 0.9, 0.1);
  }

  25%,
  50% {
    transform: translate3d(0px, 0px, -50px) rotateY(180deg)
      scale3d(0.9, 0.9, 0.1);
  }
}

/* The side */
.side {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transform-style: preserve-3d;
  backface-visibility: hidden;
}

/* back */
.side:nth-child(1) {
  transform: rotateX(-180deg) translateZ(50px);
  background-color: #1d1d77;
}

/* left side */
.side:nth-child(2) {
  transform: rotateY(-90deg) translateZ(50px);
  background-color: #4677c7;
}

/* right side */
.side:nth-child(3) {
  transform: rotateY(90deg) translateZ(50px);
  background-color: #4677c7;
}

/* top side */
.side:nth-child(4) {
  transform: rotateX(90deg) translateZ(50px);
  background-color: #2b3b92;
}

/* bottom side */
.side:nth-child(5) {
  transform: rotateX(-90deg) translateZ(50px);
  background-color: #2b3b92;
}

/* top */
.side:nth-child(6) {
  transform: translateZ(50px);
  background-color: #1d1d77;
}
