// table start

.default_table {
  thead,
  tbody {
    vertical-align: bottom;
    border-color: inherit;
    border-style: solid;
    border-width: 0;

    tr {
      border-color: inherit;
      border-style: solid;
      border-width: 0;
      overflow: auto;

      th,
      td {
        border-top: 0;
        border-bottom: 1px solid #e4e4e4 !important;
        padding: 15px 20px;
        font-weight: 500;
        text-transform: capitalize;
        font-family: Poppins, sans-serif;
        text-align: center;
        word-break: keep-all;
      }
      th {
        font-weight: 600;
      }
    }
  }
}

// table end

// media query start

@media only screen and (max-width: 768px) {
  // table start

  .default_table {
    thead,
    tbody {
      tr {
        th,
        td {
          font-size: 13px;
          font-weight: 400;
          padding: 5px 2px;
          border: 1px solid rgb(198, 197, 197);
        }
        th {
          font-weight: 400;
        }
      }
    }
  }

  // table end
}
